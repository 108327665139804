<template>
  <div class="row m-0 pt-3">
    <div class="col-12 mb-1 mb-md-2 p-0 color_sand" :class="mobile ? 'font5' : 'font3'">
      {{label}}
    </div>
    <div class="col-12 mb-3 p-0 pt-3">
      <input :type="type" class="form-control form-control-dark" :id="inputId" :placeholder="placeholder" :disabled="disabled" v-model="inputValue" @input="updateValue()" :required="required"
        @blur="$emit('blur')"
        @focus="$emit('focus')"
        @keyup="$emit('keyup')"
      >
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
export default {
  name: 'FormInput',
  props: ['label', 'type', 'inputId', 'placeholder', 'required', 'value', 'disabled', 'mobile'],
  data: () => ({
    inputValue: ''
  }),
  watch: {
    value () {
      Vue.set(this, 'inputValue', this.value)
    },
    inputValue () {
      Vue.set(this, 'inputValue', this.value)
    }

  },
  created () {
    this.inputValue = this.value
  },
  methods: {
    updateValue () {
      this.$emit('input', this.inputValue)
    }
  }
}
</script>
<style scoped>
/* .form-control {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 0.9;
} */
/* .darkbg .form-control, .bg_gradient .form-control {
  background: #C3C3C3 0% 0% no-repeat padding-box;
  opacity: 0.1;
  border: none;
  color: #fff;
}
*/
/* ::-webkit-input-placeholder {
    color: var(--secondarycolor);
}
::-moz-placeholder {
  color: var(--secondarycolor);
}
:-ms-input-placeholder {
  color: var(--secondarycolor);
}
::-ms-input-placeholder {
  color: var(--secondarycolor);
}
::placeholder {
  color: var(--secondarycolor);
}
  .darkbg .form-control, .bg_gradient .form-control {
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    color: #fff;
  }
  .darkbg .form-control::-webkit-input-placeholder {
    color: #fff;
    opacity: 1;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
  }
  .darkbg .form-control::-moz-placeholder {
    color: #fff;
    opacity: 1;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
  }
  .darkbg .form-control:-ms-input-placeholder {
    color: #fff;
    opacity: 1;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
  }
  .darkbg .form-control::-ms-input-placeholder {
    color: #fff;
    opacity: 1;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
  }
  .darkbg .form-control::placeholder {
    color: #fff;
    opacity: 1;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
  } */
</style>
